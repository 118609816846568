export const FirebaseConfig = {
	"projectId": "snappy-3e2cd",
	"appId": "1:766814480096:web:b17c2c83727a27e4c65285",
	"databaseURL": "https://snappy-3e2cd-default-rtdb.firebaseio.com",
	"storageBucket": "snappy-3e2cd.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAwYztuDKp5Aar_RCa6f2Q6WxAw64REgJ4",
	"authDomain": "snappy-3e2cd.firebaseapp.com",
	"messagingSenderId": "766814480096",
	"measurementId": "G-4NLFH635MX"
};